import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";

/* The following plugins are a Club GSAP perk */
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { SplitText } from "gsap/SplitText";

import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import marquee from 'https://cdn.jsdelivr.net/npm/vanilla-marquee/dist/vanilla-marquee.js';


gsap.registerPlugin(ScrollTrigger,ScrollSmoother,SplitText);


// if (ScrollTrigger.isTouch !== 1) {
    // only create if it's not a touch-only device...
    let smoother = ScrollSmoother.create({
        smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.1, 
    });
// }


// marquee
if(document.getElementById('marquee01')){
    new marquee( document.getElementById('marquee01'), {
        duplicated: true,
        gap: 0,
        speed: 50,
        startVisible: true
    } );
}
if(document.getElementById('marquee02')){
    new marquee( document.getElementById('marquee02'), {
        duplicated: true,
        gap: 0,
        direction:'right',
        speed: 50,
        startVisible: true
    } );
}
if(document.getElementById('marquee03')){
  new marquee( document.getElementById('marquee03'), {
      duplicated: true,
      gap: 0,
      direction:'right',
      speed: 50,
      startVisible: true
  } );
}

// swiper cards
const swiper = new Swiper('.basic-swiper', {
    // Optional parameters

    direction: 'horizontal',
    initialSlide: 1,
    spaceBetween: 15,
    loop: false,
    centeredSlides: true,
    slidesPerView: 1.5,
    breakpoints: {
        640: {
          slidesPerView: 1.75,
        },
        768: {
          slidesPerView: 2.3,
          spaceBetween: 50
        },
        1024: {
          slidesPerView: 2.75,
        },
    }
});

const navSwiper = new Swiper('.nav-swiper', {
    // Optional parameters
    modules: [Navigation],
    direction: 'horizontal',
    initialSlide: 1,
    spaceBetween: 15,
    loop: false,
    centeredSlides: true,
    slidesPerView: 1.5,
    navigation: {
        nextEl: '.swiper-cards-next',
        prevEl: '.swiper-cards-prev',
    },
    breakpoints: {
        640: {
          slidesPerView: 1.75,
        },
        768: {
          slidesPerView: 2.3,
          spaceBetween: 50
        },
        1024: {
          slidesPerView: 2.75,
        },
    }
});

// Scrolltrigger pins
const pinSections = document.querySelectorAll(".pin-section")

if(pinSections.length){
    for (let i = 0; i < pinSections.length; i++) {

        const el = pinSections[i];

        const elHeight = el.innerHeight

        let st = ScrollTrigger.create({
            trigger: el,
            start: () => el.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
            pin: true, 
            pinSpacing: false,
            onLeave: () => {
              el.classList.add('hidden')
            },
            onEnterBack: () => {
              el.classList.remove('hidden')
            }
          });
        
    }
}

// Anchor tags in pin section
gsap.utils.toArray("#pinned-cards-section a").forEach(function (button, i) {
  button.addEventListener("click", (e) => {
    var id = e.target.getAttribute("href");
    // console.log(id);
    smoother.scrollTo(id, true, "top top");
    e.preventDefault();
  });
});

// to view navigate to -  https://cdpn.io/pen/debug/XWVvMGr#section3
window.onload = (event) => {
  // console.log("page is fully loaded");

  let urlHash = window.location.href.split("#")[1];

  let scrollElem = document.querySelector("#" + urlHash);

  // console.log(scrollElem, urlHash);

  if (urlHash && scrollElem) {
    gsap.to(smoother, {
      scrollTop: smoother.offset(scrollElem, "top top"),
      duration: 1,
      delay: 0.5
    });
  }
};


// mobile nav functionality
const mobileNav = document.getElementById("mobile-nav-menu")
const navBtn = document.getElementById("mobile-nav-btn")
const navEx = document.getElementById("nav-menu-exit-btn")

if(navEx){
    navEx.addEventListener("click", function() {
        if(mobileNav.classList.contains("mobile-nav-active")) {
            mobileNav.classList.remove("mobile-nav-active")
            
        }   
    })
}
if(navBtn){
    navBtn.addEventListener("click", function() {
        if(!mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.add("mobile-nav-active")
        
        }
    })
}

// swiper card link animations
const swiperLinks = document.querySelectorAll(".swiper-card-link")
const swiperLinkHovers = document.querySelectorAll(".swiper-card-link-hover")

if(swiperLinks.length && swiperLinkHovers.length){
  for (let index = 0; index < swiperLinks.length; index++) {
    const link = swiperLinks[index];
    
    link.addEventListener("mouseover", () => {
      // gsap.to(swiperLinkHovers[i], {opacity: 1})
      swiperLinkHovers[index].style.opacity = 1
    })
    link.addEventListener("mouseout", () => {
      // gsap.to(swiperLinkHovers[i], {opacity: 1})
      swiperLinkHovers[index].style.opacity = 0
    })
  }
}